import
{
    GET_INVOICES_DATA,
    UPDATE_INVOICES_QUERY,
    UPDATE_INVOICES_LOADING_STATUS,
    OPEN_RAISE_INVOICES,
    SAVE_RAISE_INVOICES,
    DATA_RAISE_INVOICES,
    RESET_RAISE_INVOICES,
    SET_INVOICE_FILE,
    GET_SHIPPED_ORDERS_FOR_INVOICE,
    OPEN_INVOICE_ORDERS,
    GET_INVOICE_ORDERS,
    CLOSE_INVOICE_ORDERS,
    GET_UPDATE_INVOICE_DATA
}
    from
        "../actions/constants";

const initState = {
    loading: false,
    details: {
        search: '',
        page: 1,
        limit: 10,
        pages: 1,
        total: 1,
    },
    invoicesList: [],
    raiseInvoice: {
        open: false,
        data: {
            invoice_number: '',
            invoice: '',
            trans_ref: '',
            tracking_id: '',
            delivery_partner: '',
            proc_ids: []
        },
        loading: true,
        saving: false,
        type: 'Raise'
    },
    shippedOrdersForInvoice: [],
    invoiceOrdersData: {
        open: false,
        data: []
    }
}

const reducer = (state=initState, action) => {
    let raiseInvoice = {...state.raiseInvoice};
    let invoiceOrdersData = {...state.invoiceOrdersData};

    switch (action.type) {
        case GET_INVOICES_DATA:
            return {
                ...state,
                invoicesList: action.payload.docs,
                details: {...state.details, ...action.payload.details}
            };
        case UPDATE_INVOICES_QUERY:
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.payload
                }
            };
        case UPDATE_INVOICES_LOADING_STATUS:
            return {
                ...state,
                loading: action.payload
            };
        case OPEN_RAISE_INVOICES:
            raiseInvoice.type = 'Raise';
            raiseInvoice.open = action.payload;
            return {
                ...state,
                raiseInvoice
            };
        case DATA_RAISE_INVOICES:
            raiseInvoice.data = {...raiseInvoice.data, ...action.payload};
            return {
                ...state,
                raiseInvoice
            };
        case SAVE_RAISE_INVOICES:
            raiseInvoice.saving = action.payload;
            return {
                ...state,
                raiseInvoice
            };
        case RESET_RAISE_INVOICES:
            raiseInvoice.data = [];
            raiseInvoice.open = false;
            raiseInvoice.saving = false;
            return {
                ...state,
                raiseInvoice
            };
        case SET_INVOICE_FILE:
            raiseInvoice.data.invoice = action.payload;
            return {
                ...state,
                raiseInvoice
            };
        case GET_SHIPPED_ORDERS_FOR_INVOICE:
            let orders = [];
            for (let data of action.payload) {
                orders.push({label: `${data.proc_id} (${data.title})`, value: data.id})
            }
            return {
                ...state,
                shippedOrdersForInvoice: orders
            };
        case OPEN_INVOICE_ORDERS:
            invoiceOrdersData.open = action.payload;
            return {
                ...state,
                invoiceOrdersData
            };
        case CLOSE_INVOICE_ORDERS:
            invoiceOrdersData.data = [];
            invoiceOrdersData.open = false;
            return {
                ...state,
                invoiceOrdersData
            };
        case GET_INVOICE_ORDERS:
            invoiceOrdersData.data = [];
            invoiceOrdersData.data = [...invoiceOrdersData.data, ...action.payload];
            return {
                ...state,
                invoiceOrdersData
            };
        case GET_UPDATE_INVOICE_DATA:
            raiseInvoice.type = 'Update';
            raiseInvoice.data = action.payload;
            return {
                ...state,
                raiseInvoice
            };
        default:
            return state;
    }
}

export default reducer;