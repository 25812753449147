import React, { Component } from 'react';
import Signin from '../components/Signin';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';

import { connect } from 'react-redux';
import { LoginAPI } from '../api/auth';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      open: false,
      message: ''
    }
    this.changeValue = this.changeValue.bind(this);
  }
  changeValue(e) {

    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleClick = state => () => {
    this.setState({ open: true, ...state });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    let redirectToHome;
    if(this.props.isLoggedIn) { redirectToHome = <Redirect to='/pending-orders'/>; }
    return (
      <div>
        {redirectToHome}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal:'center' }}
          open={this.props.signInFail}
          onClose={this.state.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.props.signInFailMsg.join(", ")}</span>}
        />
        <Signin
          loading={this.props.loading}
          username={this.state.username}
          password={this.state.password}
          changeValue={this.changeValue}
          signin={this.props.signIn}/>
      </div>
    );
  }
}

Login.propTypes = {
  loading: PropTypes.bool,
  isLoggedIn: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoggedIn: state.login.isLoggedIn,
  loading: state.login.loading,
  signInFail: state.login.signInFail,
  signInFailMsg: state.login.signInFailMsg
})

const mapDispatchToProps = {
  signIn: LoginAPI
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
