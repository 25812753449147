import { combineReducers } from 'redux';
import login from './login';
import notify from './notify';
import admin from './admin';
import pendingOrders from './pendingOrders';
import shippedOrders from './shippingOrders';
import invoice from './invoices';

export default combineReducers({
    login,
    notify,
    admin,
    pendingOrders,
    shippedOrders,
    invoice
})
