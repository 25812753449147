import
{
    GET_PENDING_ORDERS_DATA,
    UPDATE_PENDING_ORDERS_QUERY,
    UPDATE_PENDING_ORDERS_LOADING_STATUS,
    OPEN_PENDING_ORDERS_TRACKING,
    GET_PENDING_ORDERS_TRACKING,
    CLOSE_PENDING_ORDERS_TRACKING
}
    from
        "./constants";

export const GetPendingOrdersData = (payload) => ({
    type: GET_PENDING_ORDERS_DATA,
    payload: payload
});

export const UpdatePendingOrdersQuery = (payload) => ({
    type: UPDATE_PENDING_ORDERS_QUERY,
    payload: payload
});

export const UpdatePendingOrdersLoadingStatus = (payload) => ({
    type: UPDATE_PENDING_ORDERS_LOADING_STATUS,
    payload: payload
});

export const OpenPendingOrdersTracking = (payload) => ({
    type: OPEN_PENDING_ORDERS_TRACKING,
    payload: payload
});

export const GetPendingOrdersTracking = (payload) => ({
    type: GET_PENDING_ORDERS_TRACKING,
    payload: payload
});

export const ClosePendingOrdersTracking = (payload) => ({
    type: CLOSE_PENDING_ORDERS_TRACKING,
    payload: payload
});