import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "../common/dialogCloseButton";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import Divider from "@material-ui/core/Divider";
import {DataRaiseInvoice, ResetRaiseInvoice, SetInvoiceFile} from "../../store/actions/invoices";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import OrdersSearch from './ordersMultiSelect';

function RaiseInvoice(props) {
    const changeValue = (e) => {
        props.DataRaiseInvoice({[e.target.name]: e.target.value})
    }
    const getSelectedOrders = (data) => {
        let ids = [];
        for(let d of data) {
            ids.push(d.value)
        }
        props.DataRaiseInvoice({proc_ids: ids})
    }
    const { fullScreen } = props;
    const invoice = props.add.data;
    const onSubmit = (e) => {
        e.preventDefault();
        if (props.type === 'Raise') {
            props.raiseInvoiceAPI(invoice);
        } else {
            props.updateInvoiceAPI(invoice.id, invoice)
        }
    };
    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                open={props.add.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" onClose={props.ResetRaiseInvoice}>{"Raise Invoice"}</DialogTitle>
                <Divider/>
                <DialogContent>
                    <form onSubmit={(e)=>onSubmit(e)} autoComplete="off">
                        <TextField
                            required
                            name="invoice_number"
                            type="text"
                            label="Invoice Number"
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            id="outlined-size-small"
                            value={invoice.invoice_number}
                            autoComplete="new-password"
                            onChange={(e)=>changeValue(e)}
                        />
                        <TextField
                            required
                            name="trans_ref"
                            type="text"
                            label="Transaction Remarks"
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            value={invoice.trans_ref}
                            size="small"
                            id="outlined-size-small"
                            autoComplete="new-password"
                            onChange={(e)=>changeValue(e)}
                        />
                        <TextField
                            required
                            name="tracking_id"
                            type="text"
                            label="Tracking ID"
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            value={invoice.tracking_id}
                            size="small"
                            id="outlined-size-small"
                            autoComplete="new-password"
                            onChange={(e)=>changeValue(e)}
                        />
                        <TextField
                            required
                            name="delivery_partner"
                            type="text"
                            label="Delivery Partner"
                            margin="normal"
                            variant="outlined"
                            fullWidth={true}
                            value={invoice.delivery_partner}
                            size="small"
                            id="outlined-size-small"
                            autoComplete="new-password"
                            onChange={(e)=>changeValue(e)}
                        />
                        {props.type === 'Raise' ? 
                            <div>
                                <FormLabel component="legend" style={{marginTop: 13, marginBottom: 0}}>Upload Invoice</FormLabel>
                                <input
                                    name="file"
                                    type="file"
                                    accept=".pdf"
                                    // value={pack.file}
                                    style={{
                                        position: 'relative',
                                        width: '230px',
                                        padding: '10px',
                                        WebkitBorderRadius: '5px',
                                        MozBorderRadius: '5px',
                                        border: '1px dashed #BBB',
                                        textAlign: 'center',
                                        backgroundColor: '#DDD',
                                        cursor: 'pointer',
                                        marginTop: '15px'
                                    }}
                                    onChange={(e)=>{props.SetInvoiceFile({image: e.target.files[0]})}}
                                />
                            </div>
                        : null}
                        {props.type === 'Raise' ? 
                            <div style={{marginTop: 23}}>
                                <OrdersSearch
                                    ordersListAPI={props.GetShippedOrdersForInvoice}
                                    getSelectedOrders={getSelectedOrders}
                                />
                            </div>
                        : null}
                        
                        <Divider style={{marginTop: 100}} />
                        <DialogActions>
                            <Button color="primary" type="submit" disabled={props.add.saving}>
                                {(props.add.saving)?'Saving...': 'Save'}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => ({
    type: state.invoice.raiseInvoice.type
});

const mapDispatchToProps = dispatch => ({
    DataRaiseInvoice: (data) => dispatch(DataRaiseInvoice(data)),
    ResetRaiseInvoice: (data) => dispatch(ResetRaiseInvoice(data)),
    SetInvoiceFile: (file) => dispatch(SetInvoiceFile(file)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(RaiseInvoice));
