import React from 'react';

import PendingOrdersIcon from '@material-ui/icons/DateRange';
import ShippedIcon from '@material-ui/icons/EventAvailable';
import InvoiceIcon from '@material-ui/icons/Receipt';

import PendingOrdersList from "../../pages/pendingOrders";
import ShippedOrdersList from "../../pages/shippingOrders";
import InvoiceList from "../../pages/invoices";

const initState = {
    menuList: [
        {link: '/pending-orders', name: 'Pending Orders', icon: <PendingOrdersIcon style={{color: '#fff'}}/>, perm: ''},
        {link: '/shipped-orders', name: 'Shipped Orders', icon: <ShippedIcon style={{color: '#fff'}}/>, perm: ''},
        // {link: '/invoices', name: 'Invoices', icon: <InvoiceIcon style={{color: '#fff'}}/>, perm: ''}
    ],
    routes: [
        {link: '/pending-orders', comp: PendingOrdersList, perm: ''},
        {link: '/shipped-orders', comp: ShippedOrdersList, perm: ''},
        // {link: '/invoices', comp: InvoiceList, perm: ''}
    ],
    menus: []
};

const reducer = (state=initState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
