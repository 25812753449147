import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';
import {CloseInvoiceOrders} from "../../store/actions/invoices";
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import DialogTitle from "../common/dialogCloseButton"
import TableHeaders from '../common/tableHeaders';

function InvoiceOrders(props) {
    const { fullScreen } = props;
    let data = props.orders.data;
    const rows = [
        {label: 'Procurement ID', id: 'proc_id'},
        {label: 'Name', id: 'title'},
        {label: 'Category', id: 'category'}
    ];
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.orders.open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" onClose={props.closeInvoiceOrders}>{"Invoice Orders"}</DialogTitle>
            <Divider />
            <DialogContent style={{paddingTop: 10,paddingBottom: 30}}>
                {data.length ? <Table>
                    <TableHeaders
                            headers={rows}
                        />
                        <TableBody>
                            {data.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <TableCell>{n.proc_id}</TableCell>
                                        <TableCell>{n.title}</TableCell>
                                        <TableCell>{n.category}</TableCell>
                                    </TableRow>
                                )}
                            )}
                        </TableBody>
                </Table>
                : <div style={{paddingTop: 10}}>No Orders Found</div>}
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    orders: state.invoice.invoiceOrdersData
});

const mapDispatchToProps = dispatch => ({
    closeInvoiceOrders: (data) => dispatch(CloseInvoiceOrders(data)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(InvoiceOrders));
