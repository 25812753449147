import
{
    GET_PENDING_ORDERS_DATA,
    UPDATE_PENDING_ORDERS_QUERY,
    UPDATE_PENDING_ORDERS_LOADING_STATUS,
    OPEN_PENDING_ORDERS_TRACKING,
    GET_PENDING_ORDERS_TRACKING,
    CLOSE_PENDING_ORDERS_TRACKING
}
    from
        "../actions/constants";

const initState = {
    loading: false,
    details: {
        search: '',
        page: 1,
        limit: 10,
        pages: 1,
        total: 1,
    },
    pendingOrdersList: [],
    pendingOrdersTracking: {
        open: false,
        tracks: []
    },
}

const reducer = (state=initState, action) => {
    let pendingOrdersTracking = {...state.pendingOrdersTracking};
    switch (action.type) {
        case GET_PENDING_ORDERS_DATA:
            return {
                ...state,
                pendingOrdersList: action.payload.docs,
                details: {...state.details, ...action.payload.details}
            };
        case UPDATE_PENDING_ORDERS_QUERY:
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.payload
                }
            };
        case UPDATE_PENDING_ORDERS_LOADING_STATUS:
            return {
                ...state,
                loading: action.payload
            };
        case OPEN_PENDING_ORDERS_TRACKING:
            pendingOrdersTracking = {...pendingOrdersTracking, ...action.payload};
            return {
                ...state,
                pendingOrdersTracking
            };
        case CLOSE_PENDING_ORDERS_TRACKING:
            pendingOrdersTracking.tracks = [];
            pendingOrdersTracking.open = false;
            return {
                ...state,
                pendingOrdersTracking
            };
        case GET_PENDING_ORDERS_TRACKING:
            pendingOrdersTracking.tracks = [];
            pendingOrdersTracking.tracks = [...pendingOrdersTracking.tracks, ...action.payload];
            return {
                ...state,
                pendingOrdersTracking
            };
        default:
            return state;
    }
}

export default reducer;