import {
    GetPendingOrdersData,
    UpdatePendingOrdersLoadingStatus,
    GetPendingOrdersTracking
} from "../store/actions/pendingOrders";
import api from './base';
import {UpdateNotify} from '../store/actions/notify';
import {SignOut} from '../store/actions/index';
import {
    PENDING_ORDERS_LIST_API,
    APPROVE_PENDING_ORDER,
    PENDING_ORDERS_TRACKING_API
}
    from
        "./apis";

export function PendingOrdersListAPI(page=1, limit=10, search='') {

    return function (dispatch) {
        const url = `${PENDING_ORDERS_LIST_API}?page=${page}&page_size=${limit}&search=${search}&order_status=pending&seller_id=${localStorage.user_id}`;
        dispatch(UpdatePendingOrdersLoadingStatus(true));
        api(url)
            .then((resp) => {
                const details = {
                    'limit': resp.pageInfo.resultsPerPage,
                    'page': resp.previous + 1,
                    'total': resp.pageInfo.totalResults,
                    'total_record': resp.pageInfo.totalResults
                };
                dispatch(GetPendingOrdersData({docs: resp.results, details}));
                dispatch(UpdatePendingOrdersLoadingStatus(false));
            }, (error) => {
                dispatch(UpdatePendingOrdersLoadingStatus(false));
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            } else {
                dispatch(UpdateNotify({open: true, msgs:'Something went wrong, try after some time'}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response && error.response.status===401){return dispatch(SignOut())}
        })
    }
}

export function ApproveOrderAPI(id, data) {
    return function (dispatch) {
        let API = `${APPROVE_PENDING_ORDER}${id}`;
        api(API, 'PUT', data)
        .then((resp) => {
            PendingOrdersListAPI()(dispatch);
            dispatch(UpdateNotify({open: true, msgs:["Successfully Updated"]}));
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
        }, (error) => {
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            } else {
                dispatch(UpdateNotify({open: true, msgs:[error.response.data.message]}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response.status===401){return dispatch(SignOut())}
        })
    }
}

export function TrackingDetailsAPI(id='') {
    return function (dispatch) {
        const url = `${PENDING_ORDERS_TRACKING_API}?proc_id=${id}`;
        api(url)
        .then((resp) => {
            dispatch(GetPendingOrdersTracking(resp))
        }, (error) => {
            dispatch(GetPendingOrdersTracking([]));
            if (error.response.data.status_code === 400) {
                dispatch(UpdateNotify(error.response.data.message))
            }
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            }  else {
                dispatch(UpdateNotify({open: true, msgs:[error.response.data.message]}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response && error.response.status===401){return dispatch(SignOut())}
        })
    }
}