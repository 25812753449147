import {DJANGO_API, NODE_API} from "./settings";

export const LOGIN_API = DJANGO_API + 'account/login/';
export const STATUS_API = DJANGO_API + 'account/profile/';

// sellers pending orders
export const PENDING_ORDERS_LIST_API = DJANGO_API + 'order/proc-list/';
export const APPROVE_PENDING_ORDER = DJANGO_API + 'order/proc-order-update/';
export const PENDING_ORDERS_TRACKING_API = DJANGO_API + 'order/proc-tracking-details/';

//sellers shipping orders
export const SHIPPING_ORDERS_LIST_API = DJANGO_API + 'order/proc-list/';

// invoices
export const INVOICES_LIST_API = DJANGO_API + 'order/proc-invoice/';
export const RAISE_INVOICE_API = DJANGO_API + 'order/proc-invoice/';
export const INVOICE_UPDATE_API = DJANGO_API + 'order/update-invoice/';

