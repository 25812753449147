import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHeaders from '../common/tableHeaders';
import { connect } from 'react-redux';
import {
    UpdateShippingOrdersQuery
} from "../../store/actions/shippingOrders";
import {ShippingOrdersListAPI} from "../../api/shippingOrders";
import TextField from "@material-ui/core/TextField/TextField";
import Title from "../common/title";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {DJANGO_MEDIA_HOST} from "../../api/settings"

const rows = [
    { id: 'order_creation_date', numeric: false, disablePadding: false, label: 'Order Creation Date' },
    { id: 'display_title', numeric: false, disablePadding: false, label: 'Product Name' },
    { id: 'image', numeric: false, disablePadding: false, label: 'Product Image' },
    { id: 'catagory', numeric: false, disablePadding: false, label: 'Product Catagory' },
    { id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity' },
    { id: 'unit_price', numeric: false, disablePadding: false, label: `Unit Price` },
    { id: 'total_price', numeric: false, disablePadding: false, label: 'Total Price' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'ref_id', numeric: false, disablePadding: false, label: 'Order ID' },
    { id: 'proc_id', numeric: false, disablePadding: false, label: 'Procurement ID' }
]

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: 'white'
    },
    table: {
        minWidth: 1500,

    },
    tableWrapper: {
        overflowX: 'auto',
    },
    search: {
        position: 'relative',
        display: 'block', // Fix IE 11 issue.
        [theme.breakpoints.up(1000 + theme.spacing(3 * 2))]: {
            width: 650,
            paddingTop: 0
        }
    },
    progressbar: {
        backgroundColor: 'rgb(167, 202, 237)'
    },
    actions: {
        height: 50,
        [theme.breakpoints.up(1000 + theme.spacing(3 * 2))]: {
            height: 55
        }
    },
    tableCell: {
        width: 150
    },
    dateCell: {
        width: 180
    },
    MuiTableCellRoot: {
        padding: 6,
        paddingLeft: 16
    },
    refreshButton: {
        float: 'right',
        cursor: 'pointer',
        paddingTop: 8
    },
});

function ShippedOrdersTable(props) {
    function onSearch(term) {
        if (term.length >= 3) {
            props.updateQuery({search: term})
        } else if(term.length === 0) {
            props.updateQuery({search: ''})
        }
    }
    function getStripedStyle(index) {
        return { background: index % 2 ? '#f3f3f3' : 'white' };
    }
    const { classes } = props;
    const emptyRows = props.details.limit - props.shippedOrders.length;
    return (
        <div>
            <Title
                title={'Shipped Orders List'}
                RefreshAPI={() => props.ListAPI(props.details.page, props.details.limit, props.details.search)}
            />
            <div className={classes.actions}>
                <TextField size="small" id="outlined-size-small" className={classes.search} autoComplete="off" fullWidth={true} id="outlined-search" placeholder="Search Order ID / Procurement ID" variant="outlined" type="search" margin="normal" onChange={(event)=>onSearch(event.target.value)} />
            </div>
            <div style={{height: 5}}>
                {props.loading ? <LinearProgress className={classes.progressbar} /> : ''}
            </div>
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHeaders
                            headers={rows}
                            numSelected={props.shippedOrders.length}
                            rowCount={props.shippedOrders.length}
                        />
                        <TableBody>
                            {props.shippedOrders.map((n, i) => {
                                return (
                                    <TableRow
                                        hover
                                        className={classes.MuiTableCellRoot}
                                        // role="checkbox"
                                        // tabIndex={-1}
                                        key={n.id}
                                        style={{...getStripedStyle(i)}}
                                    >
                                        <TableCell className={classes.tableCell && classes.MuiTableCellRoot}>{n.added_at}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.title}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>
                                            {n.product_images.length ? <a href={DJANGO_MEDIA_HOST + n.product_images[0]} style={{textDecoration: 'none', color: 'rgb(23, 105, 170)', fontWeight: 'bold'}} rel="noopener noreferrer" target="_blank">View</a> : ''}
                                        </TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.category}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.qty}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.selling_price / n.qty}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.selling_price}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.status_display}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.ref_id}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.proc_id}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={props.details.total}
                    rowsPerPage={props.details.limit}
                    page={props.details.page-1}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={(event, page)=>props.updateQuery({page: page + 1})}
                    onChangeRowsPerPage={(event)=>props.updateQuery({limit: event.target.value, page: 1})}
                />
            </Paper>
        </div>
    );
}

const mapStateToProps = state => ({
    loading: state.shippedOrders.loading
});

const mapDispatchToProps = dispatch => ({
    updateQuery: (data) => dispatch(UpdateShippingOrdersQuery(data)),
    ListAPI: (page, limit, search) => dispatch(ShippingOrdersListAPI(page, limit, search))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ShippedOrdersTable));
