import {
    GetShippingOrdersData,
    UpdateShippingOrdersLoadingStatus
} from "../store/actions/shippingOrders";
import api from './base';
import {UpdateNotify} from '../store/actions/notify';
import {SignOut} from '../store/actions/index';
import {
    SHIPPING_ORDERS_LIST_API
}
    from
        "./apis";

export function ShippingOrdersListAPI(page=1, limit=10, search='') {

    return function (dispatch) {
        const url = `${SHIPPING_ORDERS_LIST_API}?page=${page}&page_size=${limit}&search=${search}&order_status=shipped&seller_id=${localStorage.user_id}`;
        dispatch(UpdateShippingOrdersLoadingStatus(true));
        api(url)
            .then((resp) => {
                const details = {
                    'limit': resp.pageInfo.resultsPerPage,
                    'page': resp.previous + 1,
                    'total': resp.pageInfo.totalResults,
                    'total_record': resp.pageInfo.totalResults
                };
                dispatch(GetShippingOrdersData({docs: resp.results, details}));
                dispatch(UpdateShippingOrdersLoadingStatus(false));
            }, (error) => {
                dispatch(UpdateShippingOrdersLoadingStatus(false));
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            } else {
                dispatch(UpdateNotify({open: true, msgs:'Something went wrong, try after some time'}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response && error.response.status===401){return dispatch(SignOut())}
        })
    }
}