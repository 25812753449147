import {
    GetInvoiceData,
    UpdateInvoiceLoadingStatus,
    SaveRaiseInvoice,
    ResetRaiseInvoice,
    GetShippedOrdersForInvoice,
    GetInvoiceOrders
} from "../store/actions/invoices";
import api from './base';
import {UpdateNotify} from '../store/actions/notify';
import {SignOut} from '../store/actions/index';
import {
    INVOICES_LIST_API,
    RAISE_INVOICE_API,
    INVOICE_UPDATE_API,
    SHIPPING_ORDERS_LIST_API
}
    from
        "./apis";

export function InvoiceListAPI(page=1, limit=10, search='') {

    return function (dispatch) {
        const url = `${INVOICES_LIST_API}?page=${page}&page_size=${limit}&search=${search}&seller__user__user_id=${localStorage.user_id}`;
        dispatch(UpdateInvoiceLoadingStatus(true));
        api(url)
            .then((resp) => {
                const details = {
                    'limit': resp.pageInfo.resultsPerPage,
                    'page': resp.previous + 1,
                    'total': resp.pageInfo.totalResults,
                    'total_record': resp.pageInfo.totalResults
                };
                dispatch(GetInvoiceData({docs: resp.results, details}));
                dispatch(UpdateInvoiceLoadingStatus(false));
            }, (error) => {
                dispatch(UpdateInvoiceLoadingStatus(false));
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            } else {
                dispatch(UpdateNotify({open: true, msgs:'Something went wrong, try after some time'}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response && error.response.status===401){return dispatch(SignOut())}
        })
    }
}

export function RaiseInvoiceAPI(data) {
    data.user_id = localStorage.user_id;
    return function (dispatch) {
        dispatch(SaveRaiseInvoice(true));
        let postData = getFormData(data)
        api(RAISE_INVOICE_API, 'POST', postData)
        .then((resp) => {
            InvoiceListAPI()(dispatch);
            dispatch(SaveRaiseInvoice(false));
            dispatch(ResetRaiseInvoice());
            dispatch(UpdateNotify({open: true, msgs:["Successfully created"]}));
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
        }, (error) => {
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            } else {
                dispatch(UpdateNotify({open: true, msgs:[error.response.data.message]}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            dispatch(SaveRaiseInvoice(false));
            if(error.response.status===401){return dispatch(SignOut())}
        })
    }
}

function getFormData(data) {
    let formData = new FormData();
    for (let key in data) {
        if (key === 'proc_ids') {
            formData.append(key, `[${data[key]}]`)
        } else if (key !== 'invoice') {
            if (data[key]) {
                formData.append(key, data[key] ? data[key] : '');
            }
        } else {
            if (data.invoice.image) {
                formData.append('invoice', data.invoice.image, data.invoice.image.name);
            }
        }

    }
    return formData;
}

export function GetShippedOrdersForInvoiceAPI(search='') {
    return function (dispatch) {

        const url = `${SHIPPING_ORDERS_LIST_API}?page_size=10&page=1&order_status=shipped&search=${search}&seller_id=${localStorage.user_id}`;
        api(url)
        .then(({results}) => {
            dispatch(GetShippedOrdersForInvoice(results))
        }, (error) => {
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            } else {
                dispatch(UpdateNotify({open: true, msgs:'Something went wrong, try after some time'}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response && error.response.status===401){return dispatch(SignOut())}
        })
    }
}

export function InvoiceOrdersAPI(number='') {
    return function (dispatch) {
        const url = `${SHIPPING_ORDERS_LIST_API}?page=1&page_size=1000&seller_id=${localStorage.user_id}&invoice_number=${number}`;
        api(url)
        .then((resp) => {
            dispatch(GetInvoiceOrders(resp.results))
        }, (error) => {
            dispatch(GetInvoiceOrders([]));
            if (error.response.data.status_code === 400) {
                dispatch(UpdateNotify(error.response.data.message))
            }
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            }  else {
                dispatch(UpdateNotify({open: true, msgs:[error.response.data.message]}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response && error.response.status===401){return dispatch(SignOut())}
        })
    }
}

export function UpdateInvoiceAPI(id, data) {
    return function (dispatch) {
        let API = `${INVOICE_UPDATE_API}${id}`;
        data.login_type = 'station';
        api(API, 'PUT', data)
        .then((resp) => {
            InvoiceListAPI()(dispatch);
            dispatch(ResetRaiseInvoice());
            dispatch(UpdateNotify({open: true, msgs:["Successfully Updated"]}));
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
        }, (error) => {
            if (error.message === 'Network Error') {
                dispatch(UpdateNotify({open: true, msgs:["Check your internet connection"]}));
            } else {
                dispatch(UpdateNotify({open: true, msgs:[error.response.data.message]}));
            }
            setTimeout(()=>dispatch(UpdateNotify({open:false,msgs:[]})), 3000)
            if(error.response.status===401){return dispatch(SignOut())}
        })
    }
}