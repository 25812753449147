import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHeaders from '../common/tableHeaders';
import { connect } from 'react-redux';
import {
    UpdateInvoiceQuery,
    OpenRaiseInvoice,
    OpenInvoiceOrders,
    GetUpdateInvoiceData
} from "../../store/actions/invoices";
import {InvoiceListAPI} from "../../api/invoices";
import TextField from "@material-ui/core/TextField/TextField";
import Title from "../common/title";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {DJANGO_MEDIA_HOST} from "../../api/settings"
import Button from "@material-ui/core/Button";

const rows = [
    { id: 'added_at', numeric: false, disablePadding: false, label: 'Added At' },
    { id: 'invoice_number', numeric: false, disablePadding: false, label: 'Invoice Number' },
    { id: 'seller_name', numeric: false, disablePadding: false, label: 'Seller' },
    { id: 'tracking_id', numeric: false, disablePadding: false, label: 'Tracking ID' },
    { id: 'delivery_partner', numeric: false, disablePadding: false, label: 'Delivery Partner' },
    { id: 'Status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'trans_ref', numeric: false, disablePadding: false, label: 'Remarks' },
    { id: 'invoice_file', numeric: false, disablePadding: false, label: 'Invoice' },
    { id: 'orders', numeric: false, disablePadding: false, label: 'Orders' },
    // { id: 'update', numeric: false, disablePadding: false, label: 'Update' }
]

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: 'white'
    },
    table: {
        minWidth: 1000,

    },
    tableWrapper: {
        overflowX: 'auto',
    },
    // search: {
    //     position: 'relative',
    //     display: 'block', // Fix IE 11 issue.
    //     marginLeft: 'auto',
    //     [theme.breakpoints.up(1000 + theme.spacing(3 * 2))]: {
    //         marginLeft: 'auto',
    //         width: 500,
    //         paddingTop: 0,
    //         right: 0
    //     }
    // },
    search: {
        position: 'relative',
        display: 'block', // Fix IE 11 issue.
        [theme.breakpoints.up(1000 + theme.spacing(3 * 2))]: {
            width: 650,
            paddingTop: 0
        }
    },
    progressbar: {
        backgroundColor: 'rgb(167, 202, 237)'
    },
    actions: {
        height: 100,
        [theme.breakpoints.up(1000 + theme.spacing(3 * 2))]: {
            height: 55
        }
    },
    tableCell: {
        width: 150
    },
    dateCell: {
        width: 180
    },
    MuiTableCellRoot: {
        padding: 6,
        paddingLeft: 16
    },
    refreshButton: {
        float: 'right',
        cursor: 'pointer',
        paddingTop: 8
    },
    button: {
        position: 'relative',
        display: 'block', // Fix IE 11 issue.
        marginLeft: 0,
        color: 'white',
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            marginRight: 'auto',
            float: 'left',
            marginTop: 4,
            padding: "7px 10px 7px 10px"
        }
    },

});

function InvoiceTable(props) {
    function onSearch(term) {
        if (term.length >= 3) {
            props.updateQuery({search: term})
        } else if(term.length === 0) {
            props.updateQuery({search: ''})
        }
    }
    function getStripedStyle(index) {
        return { background: index % 2 ? '#f3f3f3' : 'white' };
    }
    const { classes } = props;
    const emptyRows = props.details.limit - props.invoices.length;
    return (
        <div>
            <Title
                title={'Invoice List'}
                RefreshAPI={() => props.ListAPI(props.details.page, props.details.limit, props.details.search)}
            />
            <div className={classes.actions}>
                {/* <Button className={classes.button} color="primary" size="small" variant="contained" onClick={(event)=> {props.raiseInvoice(true)}}>
                    Raise Invoice
                </Button> */}
                <TextField size="small" id="outlined-size-small" className={classes.search} autoComplete="off" fullWidth={true} id="outlined-search" placeholder="Search Invoice No. / Remarks / Tracking ID / Delivery Partner" variant="outlined" type="search" margin="normal" onChange={(event)=>onSearch(event.target.value)} />
            </div>
            <div style={{height: 5}}>
                {props.loading ? <LinearProgress className={classes.progressbar} /> : ''}
            </div>
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHeaders
                            headers={rows}
                            numSelected={props.invoices.length}
                            rowCount={props.invoices.length}
                        />
                        <TableBody>
                            {props.invoices.map((n, i) => {
                                return (
                                    <TableRow
                                        hover
                                        className={classes.MuiTableCellRoot}
                                        // role="checkbox"
                                        // tabIndex={-1}
                                        key={n.id}
                                        style={{...getStripedStyle(i)}}
                                    >
                                        <TableCell className={classes.MuiTableCellRoot}>{n.added_at}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.invoice_number}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.seller_id} <br/>{n.seller_name}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.tracking_id}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.delivery_partner}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.display_status}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>{n.trans_ref}</TableCell>
                                        <TableCell className={classes.MuiTableCellRoot}>
                                            {n.invoice_file ? <a href={DJANGO_MEDIA_HOST + n.invoice_file} style={{textDecoration: 'none', color: '#4099ff', fontWeight: 'bold', fontSize: '0.790rem'}} rel="noopener noreferrer" target="_blank">VIEW</a> : '-'}
                                        </TableCell>
                                        <TableCell>
                                            <Button color={'primary'} size="small" style={{fontWeight: 'bold'}} onClick={(e)=> {props.invoiceOrdersAPI(n.invoice_number);props.openInvoiceOrders(true)}}>View</Button> 
                                        </TableCell>
                                        {/* <TableCell>
                                            <Button color={'primary'} size="small" style={{fontWeight: 'bold'}} onClick={(e)=> {props.raiseInvoice(true);props.getUpdateInvoiceData(n)}}>Update</Button> 
                                        </TableCell> */}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={props.details.total}
                    rowsPerPage={props.details.limit}
                    page={props.details.page-1}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={(event, page)=>props.updateQuery({page: page + 1})}
                    onChangeRowsPerPage={(event)=>props.updateQuery({limit: event.target.value, page: 1})}
                />
            </Paper>
        </div>
    );
}

const mapStateToProps = state => ({
    loading: state.invoice.loading
});

const mapDispatchToProps = dispatch => ({
    updateQuery: (data) => dispatch(UpdateInvoiceQuery(data)),
    ListAPI: (page, limit, search) => dispatch(InvoiceListAPI(page, limit, search)),
    raiseInvoice: (status) => dispatch(OpenRaiseInvoice(status)),
    openInvoiceOrders: (status) => dispatch(OpenInvoiceOrders(status)),
    getUpdateInvoiceData: (data) => dispatch(GetUpdateInvoiceData(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(InvoiceTable));
