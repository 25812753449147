import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3 * 2))]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing(8)}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
});

const Album = (props) => {
  const { classes } = props;
    let button;
    if (!props.isLoggedIn) {
        button = <Button variant="contained" style={{color: '#fff'}} color="primary" onClick={()=> props.history.push("/login")}>Login</Button>;
    } else {
        button = <Button variant="contained" style={{color: '#fff'}} color="primary" onClick={()=> props.history.push("/pending-orders")}>Go to Dashboard</Button>;
    }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography variant="h6" style={{color: '#fff'}} color="inherit" noWrap>
              <strong>1BRIDGE</strong>
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              <span style={{color: "#fa5605"}}>1</span><span style={{color: "#4099ff"}}>BRIDGE</span>
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
                The last mile services platform delivering access, choice and convenience to rural consumers.
            </Typography>
            <div className={classes.heroButtons} >
              <Grid container spacing={10} justify="center">
                <Grid item>
                  {button}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

Album.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool
};

export default withStyles(styles)(Album);

