import
{
    GET_SHIPPING_ORDERS_DATA,
    UPDATE_SHIPPING_ORDERS_QUERY,
    UPDATE_SHIPPING_ORDERS_LOADING_STATUS
}
    from
        "../actions/constants";

const initState = {
    loading: false,
    details: {
        search: '',
        page: 1,
        limit: 10,
        pages: 1,
        total: 1,
    },
    shippingOrdersList: []
}

const reducer = (state=initState, action) => {
    switch (action.type) {
        case GET_SHIPPING_ORDERS_DATA:
            return {
                ...state,
                shippingOrdersList: action.payload.docs,
                details: {...state.details, ...action.payload.details}
            };
        case UPDATE_SHIPPING_ORDERS_QUERY:
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.payload
                }
            };
        case UPDATE_SHIPPING_ORDERS_LOADING_STATUS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}

export default reducer;