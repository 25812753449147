import
{
    GET_INVOICES_DATA,
    UPDATE_INVOICES_QUERY,
    UPDATE_INVOICES_LOADING_STATUS,
    OPEN_RAISE_INVOICES,
    SAVE_RAISE_INVOICES,
    DATA_RAISE_INVOICES,
    RESET_RAISE_INVOICES,
    SET_INVOICE_FILE,
    GET_SHIPPED_ORDERS_FOR_INVOICE,
    OPEN_INVOICE_ORDERS,
    GET_INVOICE_ORDERS,
    CLOSE_INVOICE_ORDERS,
    GET_UPDATE_INVOICE_DATA
}
    from
        "./constants";

export const GetInvoiceData = (payload) => ({
    type: GET_INVOICES_DATA,
    payload: payload
});

export const UpdateInvoiceQuery = (payload) => ({
    type: UPDATE_INVOICES_QUERY,
    payload: payload
});

export const UpdateInvoiceLoadingStatus = (payload) => ({
    type: UPDATE_INVOICES_LOADING_STATUS,
    payload: payload
});

export const OpenRaiseInvoice = (payload) => ({
    type: OPEN_RAISE_INVOICES,
    payload: payload
});

export const SaveRaiseInvoice = (payload) => ({
    type: SAVE_RAISE_INVOICES,
    payload: payload
});

export const DataRaiseInvoice = (payload) => ({
    type: DATA_RAISE_INVOICES,
    payload: payload
});

export const ResetRaiseInvoice = (payload) => ({
    type: RESET_RAISE_INVOICES,
    payload: payload
});

export const SetInvoiceFile = (payload) => ({
    type: SET_INVOICE_FILE,
    payload: payload
});

export const GetShippedOrdersForInvoice = (payload) => ({
    type: GET_SHIPPED_ORDERS_FOR_INVOICE,
    payload: payload
});

export const OpenInvoiceOrders = (payload) => ({
    type: OPEN_INVOICE_ORDERS,
    payload: payload
});

export const GetInvoiceOrders = (payload) => ({
    type: GET_INVOICE_ORDERS,
    payload: payload
});

export const CloseInvoiceOrders = (payload) => ({
    type: CLOSE_INVOICE_ORDERS,
    payload: payload
});

export const GetUpdateInvoiceData = (payload) => ({
    type: GET_UPDATE_INVOICE_DATA,
    payload: payload
});
