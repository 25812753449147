import
{
    GET_SHIPPING_ORDERS_DATA,
    UPDATE_SHIPPING_ORDERS_QUERY,
    UPDATE_SHIPPING_ORDERS_LOADING_STATUS
}
    from
        "./constants";

export const GetShippingOrdersData = (payload) => ({
    type: GET_SHIPPING_ORDERS_DATA,
    payload: payload
});

export const UpdateShippingOrdersQuery = (payload) => ({
    type: UPDATE_SHIPPING_ORDERS_QUERY,
    payload: payload
});

export const UpdateShippingOrdersLoadingStatus = (payload) => ({
    type: UPDATE_SHIPPING_ORDERS_LOADING_STATUS,
    payload: payload
});
