import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    InvoiceListAPI,
    RaiseInvoiceAPI,
    GetShippedOrdersForInvoiceAPI,
    InvoiceOrdersAPI,
    UpdateInvoiceAPI
} from "../api/invoices";
import Table from "../components/invoices/table";
import RaiseInvoice from "../components/invoices/raiseInvoice"
import InvoiceOrders from "../components/invoices/invoiceOrdersList"

class InvoiceList extends Component {
    componentWillMount() {
        this.props.InvoiceListAPI();
    }
    componentWillReceiveProps(nextProps) {
        let change = false;
        const current = nextProps.details;
        const old = this.props.details;
        if(old.page !== current.page){
            change = true;
        } else if (old.limit !== current.limit) {
            change = true;
        } else if (old.search !== current.search) {
            change = true;
        }
        if(change) {
            this.props.InvoiceListAPI(current.page, current.limit, current.search);
        }
    }
    render() {
        let raiseInvoice = '';
        if (this.props.add.open) {
            raiseInvoice = <RaiseInvoice
                add={this.props.add}
                raiseInvoiceAPI={this.props.RaiseInvoiceAPI}
                GetShippedOrdersForInvoice={this.props.GetShippedOrdersForInvoice}
                updateInvoiceAPI={this.props.updateInvoiceAPI}
            />
        }
        let invoiceOrders = '';
        if (this.props.invoiceOrders.open) {
            invoiceOrders = <InvoiceOrders/>
        }
        return (
            <div>
                <Table
                    invoices={this.props.invoices}
                    details={this.props.details}
                    changePage={this.props.InvoiceListAPI}
                    invoiceOrdersAPI={this.props.invoiceOrdersAPI}
                />
                {raiseInvoice}
                {invoiceOrders}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    invoices: state.invoice.invoicesList,
    details: state.invoice.details,
    add: state.invoice.raiseInvoice,
    invoiceOrders: state.invoice.invoiceOrdersData
});

const mapDispatchToProps = {
    InvoiceListAPI: InvoiceListAPI,
    RaiseInvoiceAPI: RaiseInvoiceAPI,
    GetShippedOrdersForInvoice: GetShippedOrdersForInvoiceAPI,
    invoiceOrdersAPI: InvoiceOrdersAPI,
    updateInvoiceAPI: UpdateInvoiceAPI
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceList);
