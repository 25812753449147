import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';
import {ClosePendingOrdersTracking} from "../../store/actions/pendingOrders";
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import DialogTitle from "../common/dialogCloseButton"
import TableHeaders from '../common/tableHeaders';

function PendingOrdersTracking(props) {
    const { fullScreen } = props;
    let data = props.ordersTracking.tracks;
    const rows = [
        {label: 'Date', id: 'added_at'},
        {label: 'Status', id: 'order_status'}
    ];
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.ordersTracking.open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" onClose={props.closeTracking}>{"Tracking Details"}</DialogTitle>
            <Divider />
            <DialogContent style={{paddingTop: 10,paddingBottom: 30}}>
                {data.length ? <Table>
                    <TableHeaders
                            headers={rows}
                        />
                        <TableBody>
                            {data.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <TableCell>{n.added_at}</TableCell>
                                        <TableCell>{n.order_status}</TableCell>
                                    </TableRow>
                                )}
                            )}
                        </TableBody>
                </Table>
                : <div style={{paddingTop: 10}}>Loading...</div>}
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    ordersTracking: state.pendingOrders.pendingOrdersTracking
});

const mapDispatchToProps = dispatch => ({
    closeTracking: (data) => dispatch(ClosePendingOrdersTracking(data)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(PendingOrdersTracking));
