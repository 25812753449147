import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import { LogoutAPI } from '../api/auth';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import LogoutIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ReceiptIcon from '@material-ui/icons/Receipt';

const drawerWidth = 230;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: '#343A45',
        fontFamily: 'sans-serif',
        color: '#fff'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7 + 1),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8 + 1),
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '5px 12px',
        // ...theme.mixins.toolbar,
        paddingTop: 70,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        width: '105%',
        marginLeft: 15,
        marginRight: 15,
        marginTop: 50,
        [theme.breakpoints.up(1000 + theme.spacing(3 * 2))]: {
            flexGrow: 1,
            padding: theme.spacing(1),
            width: 100,
            marginLeft: 15,
            marginRight: 15,
            marginTop: 50,
        }
    },
    grow: {
        flexGrow: 1,
    },
});

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({}))(MenuItem);

function CustomizedMenus(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [open, setOpen] = React.useState(true);

    // const handleMenuClick = () => {
    //     setOpen(!open);
    // };

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                //variant="inherent"
                style={{color: 'white'}}
                onClick={handleClick}
            >
                <strong>{localStorage.user_id}</strong><ArrowIcon/>
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem onClick={props.logoutAPI}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Logout'} />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}

class MiniDrawer extends React.Component {
    state = {
        open: false
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { classes } = this.props;
        let redirectToHome;
        if(!this.props.isLoggedIn) { redirectToHome = <Redirect to='/'/>; }

        if (!this.props.auth) {
            return (<div>Loading...</div>)
        }
        let menuItems = [];
        let routes = [];
        for (let i = 0; i < this.props.menuItems.length; i++) {
            if (this.props.menuItems[i].perm && this.props.permissions.includes(this.props.menuItems[i].perm)) {
                menuItems.push(this.props.menuItems[i])
            } else if (this.props.menuItems[i].perm ==='') {
                menuItems.push(this.props.menuItems[i])
            }
            if (this.props.routes[i].perm && this.props.permissions.includes(this.props.routes[i].perm)) {
                routes.push(this.props.routes[i])
            } else if (this.props.routes[i].perm ==='') {
                routes.push(this.props.routes[i])
            }
        }
        return (
            <div className={classes.root}>
                {redirectToHome}

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal:'right' }}
                    open={this.props.open}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.props.msgs[0]}</span>}
                />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h5" style={{color: 'white'}} className={classes.grow} noWrap>
                            <strong>1BRIDGE</strong>
                        </Typography>
                        <CustomizedMenus
                            logoutAPI={this.props.logoutAPI}
                        />
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                        paper: classNames(classes.drawer, {
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,
                        }),
                    }}
                    open={this.state.open}
                >
                    <div className={classes.toolbar}>
                        <span style={{marginRight: 30, fontSize: '14px', fontWeight: 'bold', color: '#fff'}}>Version 1.0.0</span>
                        {/*<h3 style={{marginRight: 15}}><span style={{color: "#fa5605"}}>1</span><span style={{color: "#0068ff"}}>BRIDGE</span></h3>*/}
                        <IconButton style={{padding: '3px'}} onClick={this.handleDrawerClose}>
                            {this.state.open ? <ChevronLeftIcon style={{color: '#fff'}}/>: <ChevronRightIcon style={{color: '#fff'}}/>}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {menuItems.map(item => {
                            return (
                                <ListItem button dense key={item.name} onClick={() => this.props.history.push(item.link)}>
                                    <ListItemIcon style={{fontSize: '14px',}}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                            )
                        })}
                        {/* <hr style={{height: 0.001}}/>
                        <ListItem button dense key="chytra">
                            <ListItemIcon style={{fontSize: '14px', color: '#fff'}}><ReceiptIcon/></ListItemIcon>
                            <ListItemText primary={"Invoices"} />
                        </ListItem> */}
                    </List>
                </Drawer>
                <main className={classes.content}>
                    <Switch>
                        {routes.map(item => {
                            return (
                                <Route exact path={item.link} key={item.link} component={item.comp}/>
                            )
                        })}
                    </Switch>
                </main>
            </div>
        );
    }
}


MiniDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    auth: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    isLoggedIn: state.login.isLoggedIn,
    auth: state.login.auth,
    open: state.notify.open,
    msgs: state.notify.msgs,
    menuItems: state.admin.menuList,
    routes: state.admin.routes,
    permissions: state.login.current.roles
})

const mapDispatchToProps = {
    logoutAPI: LogoutAPI
}

//export default withStyles(styles, { withTheme: true })(MiniDrawer);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MiniDrawer));
