import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    PendingOrdersListAPI,
    ApproveOrderAPI,
    TrackingDetailsAPI
} from "../api/pendingOrders";
import Table from "../components/pendingOrders/table";
import PendingOrdersTracking from "../components/pendingOrders/tracking"

class PendingOrdersList extends Component {
    componentWillMount() {
        this.props.PendingOrdersListAPI();
    }
    componentWillReceiveProps(nextProps) {
        let change = false;
        const current = nextProps.details;
        const old = this.props.details;
        if(old.page !== current.page){
            change = true;
        } else if (old.limit !== current.limit) {
            change = true;
        } else if (old.search !== current.search) {
            change = true;
        }
        if(change) {
            this.props.PendingOrdersListAPI(current.page, current.limit, current.search);
        }
    }
    render() {
        let pendingOrdersTracking = '';
        if (this.props.PendingOrdersTracking.open) {
            pendingOrdersTracking = <PendingOrdersTracking/>
        }
        return (
            <div>
                <Table
                    pendingOrders={this.props.pendingOrders}
                    details={this.props.details}
                    changePage={this.props.PendingOrdersListAPI}
                    ApproveOrderAPI={this.props.ApproveOrderAPI}
                    trackingDetailsAPI={this.props.trackingDetailsAPI}
                />
                {pendingOrdersTracking}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    pendingOrders: state.pendingOrders.pendingOrdersList,
    details: state.pendingOrders.details,
    PendingOrdersTracking: state.pendingOrders.pendingOrdersTracking
});

const mapDispatchToProps = {
    PendingOrdersListAPI: PendingOrdersListAPI,
    ApproveOrderAPI: ApproveOrderAPI,
    trackingDetailsAPI: TrackingDetailsAPI
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingOrdersList);
