import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
   palette: {
      primary: {
         light: '#fff',
         // main: '#1976d2',
          main: '#4099ff',
         dark: '#000'
      },
      secondary: {
        main: '#f44336',
      },
   }
   /*typography: {
      useNextVariants: true,
      fontSize: 10
   }*/
});

export default theme;
