import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from "@material-ui/core/TextField/TextField";
// import Checkbox from "@material-ui/core/Checkbox/Checkbox";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.1bridge.one/" target="_blank">
                Ruban Bridge Private Limited
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://www.1bridge.one/wp-content/uploads/2017/08/1920x1080_Supers1q.gif)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#fff',
        // backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#fff'
    },
});

function SignIn(props) {
    const { classes } = props;
  const buttonText = (props.loading)? 'Loading...' :'Sign in';
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={(e) => props.signin(e, props.username, props.password)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required={true}
                            fullWidth
                            label="User ID"
                            id="username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={props.username}
                            onChange={props.changeValue}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required={true}
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={props.password}
                            onChange={props.changeValue}
                        />
                        {/*<FormControlLabel*/}
                            {/*control={<Checkbox value="remember" color="primary" />}*/}
                            {/*label="Remember me"*/}
                        {/*/>*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {buttonText}
                        </Button>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
