import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ShippingOrdersListAPI
} from "../api/shippingOrders";
import Table from "../components/shippingOrders/table";

class ShippedOrdersList extends Component {
    componentWillMount() {
        this.props.ShippedOrdersListAPI();
    }
    componentWillReceiveProps(nextProps) {
        let change = false;
        const current = nextProps.details;
        const old = this.props.details;
        if(old.page !== current.page){
            change = true;
        } else if (old.limit !== current.limit) {
            change = true;
        } else if (old.search !== current.search) {
            change = true;
        }
        if(change) {
            this.props.ShippedOrdersListAPI(current.page, current.limit, current.search);
        }
    }
    render() {
        return (
            <div>
                <Table
                    shippedOrders={this.props.shippedOrders}
                    details={this.props.details}
                    changePage={this.props.ShippedOrdersListAPI}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    shippedOrders: state.shippedOrders.shippingOrdersList,
    details: state.shippedOrders.details
});

const mapDispatchToProps = {
    ShippedOrdersListAPI: ShippingOrdersListAPI
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippedOrdersList);
