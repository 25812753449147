// Notify
export const UPDATE_NOTIFY = "UPDATE_NOTIFY";

//SELLERS PENDING ORDERS
export const GET_PENDING_ORDERS_DATA = "GET_PENDING_ORDERS_DATA";
export const UPDATE_PENDING_ORDERS_QUERY = "UPDATE_PENDING_ORDERS_QUERY";
export const UPDATE_PENDING_ORDERS_LOADING_STATUS = "UPDATE_PENDING_ORDERS_LOADING_STATUS";
export const OPEN_PENDING_ORDERS_TRACKING = "OPEN_PENDING_ORDERS_TRACKING";
export const GET_PENDING_ORDERS_TRACKING = "GET_PENDING_ORDERS_TRACKING";
export const CLOSE_PENDING_ORDERS_TRACKING = "CLOSE_PENDING_ORDERS_TRACKING";

//SELLERS SHIPPING ORDERS
export const GET_SHIPPING_ORDERS_DATA = "GET_SHIPPING_ORDERS_DATA";
export const UPDATE_SHIPPING_ORDERS_QUERY = "UPDATE_SHIPPING_ORDERS_QUERY";
export const UPDATE_SHIPPING_ORDERS_LOADING_STATUS = "UPDATE_SHIPPING_ORDERS_LOADING_STATUS";

// INVIOCE
export const GET_INVOICES_DATA = "GET_INVOICES_DATA";
export const UPDATE_INVOICES_QUERY = "UPDATE_INVOICES_QUERY";
export const UPDATE_INVOICES_LOADING_STATUS = "UPDATE_INVOICES_LOADING_STATUS";
export const OPEN_RAISE_INVOICES = "OPEN_RAISE_INVOICES";
export const SAVE_RAISE_INVOICES = "SAVE_RAISE_INVOICES";
export const DATA_RAISE_INVOICES = "DATA_RAISE_INVOICES";
export const RESET_RAISE_INVOICES = "RESET_RAISE_INVOICES";
export const SET_INVOICE_FILE = "SET_INVOICE_FILE";
export const GET_SHIPPED_ORDERS_FOR_INVOICE = "GET_SHIPPED_ORDERS_FOR_INVOICE";
export const OPEN_INVOICE_ORDERS = "OPEN_INVOICE_ORDERS";
export const GET_INVOICE_ORDERS = "GET_INVOICE_ORDERS";
export const CLOSE_INVOICE_ORDERS = "CLOSE_INVOICE_ORDERS";
export const GET_UPDATE_INVOICE_DATA = "GET_UPDATE_INVOICE_DATA";